import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import FlaskIconPath from "./flask.svg";
import IsaacLogoPath from "./isaac.svg";

const Header = styled.header`
	order: 2;
	font-size: 50%;
	writing-mode: vertical-rl;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	user-select: none;
	border-left: 1px solid var(--meta);
	font-weight: 700;

	a {
		text-decoration: none;
	}
`;

const Text = styled.div`
	padding: 1rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
`;

const Illustration = styled.div`
	width: 100%;
	border-top: 1px solid var(--meta);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: .5rem;
`;

const Icon = styled.img`
	height: 1.5rem;
`;

const IsaacLogo = styled.img`
	height: 1rem;
	transform: rotate(90deg);
	transform-origin: 25% 100%;
`;

const Title = styled.div`
	font-family: var(--headings-font-family);
	text-transform: uppercase;
`;


export default ({ title }) => (
	<Header>
		<Text>
			<Title>
				<Link to="/1">{title}</Link>
			</Title>
			<OutboundLink
				href="https://isaac.nl"
				target="_blank"
				rel="noopener noreferrer"
			>
				Powered by
				<IsaacLogo src={IsaacLogoPath} alt="ISAAC logo" />
			</OutboundLink>
			<OutboundLink
				target="_blank"
				rel="noopener noreferrer"
				href="https://mrtnvh.com"
			>
				mrtnvh.com
			</OutboundLink>
		</Text>
		<Illustration>
			<Icon src={FlaskIconPath} alt="Icon of an test tube" />
		</Illustration>
	</Header>
);


export default {
	plain: {
		color: "#abb2bf",
		backgroundColor: "#282c34",
	},
	styles: [
		{
			types: [
				"keyword",
				"punctuation",
				"variable",
			],
			style: {
				color: "rgb(229, 192, 123)",
			},
		},
		{
			types: [
				"tag",
				"constant",
			],
			style: {
				color: "rgb(239, 89, 111)",
			},
		},
		{
			types: [
				"builtin",
			],
			style: {
				color: "rgb(209, 154, 102)",
			},
		},
		{
			types: [
				"operator",
				"char",
				"string",
			],
			style: {
				color: "rgb(43, 186, 197)",
			},
		},
		{
			types: [
				"function",
				"attr-name",
			],
			style: {
				color: "rgb(97, 175, 239)",
			},
		},
		{
			types: [
				"comment",
			],
			style: {
				color: "rgb(92, 99, 112)",
			},
		},
	],
};

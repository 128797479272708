/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import Grid, { Column } from "../components/Grid";
import Image from "../components/Image";
import Centered from "../components/Centered";
import Mark from "../components/Mark";
import CodeBlock from "../components/Code/CodeBlock";
import AspectRatio from "../components/AspectRatio";

const shortcodes = {
	Grid,
	Column,
	Image,
	Centered,
	Mark,
	AspectRatio,
	pre: (props) => <div {...props} />,
	code: CodeBlock,
	CodeBlock,
	OutboundLink,
};

export default ({ children }) => (
	<MDXProvider components={shortcodes}>{children}</MDXProvider>
);

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";

const Grid = ({
	className, full, nogap, alt, children, ...props
}) => (
	<div {...props} className={className}>{children}</div>
);

export default styled(Grid)`
	width: 100%;
	height: 100%;
	flex-grow: 1;
	display: grid;
	grid-template-columns: repeat(${(props) => props.columns || "1"}, 1fr);
	align-items: center;
	justify-content: center;
	gap: 1rem;
	${({ full }) => full && `
		width: calc(100% + 6rem);
		margin: -3rem;
	`}
	${({ nogap }) => nogap && `
		gap: 0;
		& > *:not(:first-child) {
			border-left: 1px solid var(--meta);
		}
	`}


`;

export const Column = styled.div`
	${(props) => props.alignSelf && `align-self: ${props.alignSelf}`}
`;

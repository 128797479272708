import styled from "styled-components";

export default styled.div`
	position: relative;
	content: "";
	display: block;
	padding-bottom: 50%;
	overflow: hidden;

	& > *:first-child {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: top center;
		width: 100%;
		height: 100%;
		${({ fill }) => fill && "object-fit: cover;"}
		${({ fit }) => fit && "object-fit: contain;"}
	}

	${({ bordered }) => bordered && `
		border: 1px solid var(--meta);
	`}
`;

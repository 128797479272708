/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";

const Image = ({
	className, fill, alt, ...props
}) => (
	<img {...props} className={className} alt={alt || ""} />
);

export default styled(Image)`
	${({ fill, fit }) => (fill || fit) && `
		top 0;
		left: 0;
		width: 100%;
		height: 100%;
		${fill && "object-fit: cover"};
		${fit && "object-fit: contain"};
	`}

	${({ position }) => (position ? `
		object-position: ${position} center;
	` : "object-position: top center;")
}
`;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { navigate as gatsbyNavigate, StaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";
import { useSwipeable } from "react-swipeable";
import useReactSimpleMatchMedia from "react-simple-matchmedia";
import Transition from "../components/transition";
import Header from "../components/Header";
import MDXProvider from "./MdxProvider";
import ArrowRight from "./arrow-right.svg";
import {
	Layout, Slide, Slides, ControlIcon, Previous, Next,
} from "./styles";

import "./index.css";
import dot from "./dot.svg";

const TemplateWrapper = ({
	location, children, site, data, slidesLength,
}) => {
	const NEXT = [13, 32, 39];
	const PREV = 37;
	const [now, setNow] = useState(0);
	const isTouchEnabled = useReactSimpleMatchMedia("hover: none");

	const navigate = ({ keyCode }) => {
		if (now) {
			if (keyCode === PREV && now === 1) {
				return false;
			} if (NEXT.indexOf(keyCode) !== -1 && now === slidesLength) {
				return false;
			} if (NEXT.indexOf(keyCode) !== -1) {
				gatsbyNavigate(`/${now + 1}`);
			} else if (keyCode === PREV) {
				gatsbyNavigate(`/${now - 1}`);
			}
		}

		return false;
	};

	const swipeHandlers = useSwipeable({
		onSwipeLeft: () => navigate({ keyCode: NEXT[0] }),
		onSwipeRight: () => navigate({ keyCode: NEXT[0] }),
	});

	useEffect(() => {
		if (data?.slide?.index) setNow(data.slide.index);
		document.addEventListener("keydown", navigate);
		return () => document.removeEventListener("keydown", navigate);
	});

	return (
		<MDXProvider>
			<Layout style={{ backgroundImage: `url(${dot})` }}>
				<Helmet
					title={`${site.siteMetadata.title} — ${site.siteMetadata.name}`}
				/>
				<Header title={site.siteMetadata.title} />
				<Slides {...swipeHandlers}>
					{isTouchEnabled && now > 1 && (
						<Previous onClick={() => navigate({ keyCode: PREV })}>
							<ControlIcon src={ArrowRight} />
						</Previous>
					)}
					<Transition location={location}>
						<Slide style={{ width: "100%" }}>{children}</Slide>
					</Transition>
					{isTouchEnabled && now !== slidesLength && (
						<Next onClick={() => navigate({ keyCode: NEXT[0] })}>
							<ControlIcon src={ArrowRight} />
						</Next>
					)}
				</Slides>
			</Layout>
		</MDXProvider>
	);
};

TemplateWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};

const query = graphql`
	query IndexQuery {
		site {
			siteMetadata {
				name
				title
				date
			}
		}
		allSlide {
			edges {
				node {
					id
				}
			}
		}
	}
`;

export default (props) => (
	<StaticQuery
		query={query}
		render={(data) => (
			<TemplateWrapper
				site={data.site}
				slidesLength={data.allSlide.edges.length}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
		)}
	/>
);

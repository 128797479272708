/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "./theme";

export default ({
	children, className,
}) => {
	const language = className.replace(/language-/, "");

	return (
		<Highlight {...defaultProps} code={children.trim()} language={language} theme={theme}>
			{({
				className: highlightClassname, tokens, getLineProps, getTokenProps,
			}) => (
				<pre className={highlightClassname}>
					{tokens.map((line, i) => (
						<div key={i} {...getLineProps({ line, key: i })}>
							{line.map((token, key) => (
								<span key={key} {...getTokenProps({ token, key })} />
							))}
						</div>
					))}
				</pre>
			)}
		</Highlight>
	);
};

import styled from "styled-components";

export default styled.div`
	width: 100%;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

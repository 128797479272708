import styled from "styled-components";

export const Layout = styled.div`
	background-size: 20px;
	background-position: -10px -10px;
	display: grid;
	grid-template-columns: 1fr 3rem;
`;

export const Slides = styled.div`
	order: 1;
	position: relative;
`;

export const Slide = styled.article`
	display: flex;
	overflow: hidden;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	padding: 3rem;
	-webkit-overflow-scrolling: touch;
`;

export const Previous = styled.a`
	position: absolute;
	bottom: 1.5rem;
	left: 1rem;
	transform: rotate(180deg);
`;

export const Next = styled(Previous)`
	left: auto;
	right: 1rem;
	transform: none;
`;

export const ControlIcon = styled.img`
	height: .75rem;
`;

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/zeit/60c5548d/node_modules/gatsby-remark-copy-linked-files","id":"80533a5b-ad2d-50ff-af51-726674869458","name":"gatsby-remark-copy-linked-files","version":"2.1.28","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/zeit/60c5548d/node_modules/gatsby-remark-images","id":"3450f110-2cfa-5b12-8a45-8d964eeb5e0c","name":"gatsby-remark-images","version":"3.1.29","pluginOptions":{"plugins":[],"maxWidth":1920},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-113455479-1","anonymize":true},
    }]
